import React from 'react';
import { API, Auth } from 'aws-amplify';


import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

const useStyles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1)
  }
});

class RelayBoards extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            loading : true,
            busy : false,
            boardStates : [],
            relayFormState : {}
        }

        this.handleRelayFormChange = this.handleRelayFormChange.bind(this)
    }

    handleRelayFormChange(event) {
        let relayFormState = this.state.relayFormState
        relayFormState[event.target.name] = event.target.value
        this.setState({ relayFormState : relayFormState })
    }

    componentDidMount() {
        let environmentId = (this.props.environment || {}).id
        this.getRelayBoards(environmentId)
    }

    async clickCloseRelay(address, pin) {
        let envId = (this.props.environment || {}).id

        this.setState({ busy : true })

        const data = await Auth.currentSession();
        const jwtToken = data.idToken.jwtToken;
        const payloadSub = data.idToken.payload.sub;
        const email = data.idToken.payload.email;

        let timeValue = parseInt(this.state.relayFormState[address + "_" + pin], 10)
        
        const params = { 
            headers: {},
            response: true,
            queryStringParameters: {
                jwtToken: jwtToken,
                payloadSub: payloadSub,
                email: email,
                environmentId : envId
            },
            body : {
                address : address,
                pin : pin,
                time : timeValue
            }
        }

        try {

            const state = await API.post('quicksight', '/Relay/' + envId + "/close", params);
        } catch (e) {
            console.warn(e, e.stack)
        }

        this.setState({busy : false})
    }

    async getRelayBoards(envId) {
        const data = await Auth.currentSession();
        const jwtToken = data.idToken.jwtToken;
        const payloadSub = data.idToken.payload.sub;
        const email = data.idToken.payload.email;
        
        const params = { 
            headers: {},
            response: true,
            queryStringParameters: {
                jwtToken: jwtToken,
                payloadSub: payloadSub,
                email: email,
                environmentId : envId
            }
        }

        const state = await API.get('quicksight', '/Relay/' + envId + "/state", params);
        const boardStates = state.data.boardStates

        let relayBoardStates = state.data.boards.map(function (board) {
            let state = boardStates[board.address]
            return {
                label : board.label,
                address : board.address,
                name : board.name,
                adcChannels : board.adcChannels.map(function (chan) {
                    return {
                        "index" : chan.index,
                        "label" : chan.label,
                        "value" : state.adc[chan.index]
                    }
                }),
                relayChannels : board.relayChannels.map(function (chan) {
                    return {
                        "pin" : chan.pin,
                        "label" : chan.label,
                        "defaultTime" : chan.defaultTime,
                        "on" : state.command == 2 && state.parameter == chan.pin && state.time > 0
                    }
                }),
                busy : state.time > 0,
                timestamp : new Date(state.timestamp)
            }
        })

        let relayFormState = this.state.relayFormState

        relayBoardStates.forEach(function (board) {
            board.relayChannels.forEach(function (chan) {
                if (!relayFormState[board.address + "_" + chan.pin]) {
                    relayFormState[board.address + "_" + chan.pin] = chan.defaultTime
                }
            })
        })

        this.setState({ boardStates : relayBoardStates })
    }

    render() {
        const {classes} = this.props;
        const that = this;
        return <Container> 
            { this.state.boardStates.map(function (boardState) {
                return <Container key={boardState.address} className="board">
                    <Typography variant="h2" component="h2" align="left">{ boardState.address + ": " + boardState.label }</Typography>

                    <Grid container spaceing={3}>
                        { boardState.relayChannels.map(function (relayChannel) {
                            let timeName = [boardState.address, relayChannel.pin].join("_")
                            return <Grid item xs={12} sm={3}>
                                <Paper key={relayChannel.pin} className={classes.paper}>
                                    <Typography variant="h5">{relayChannel.label}</Typography>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id={["relay-chan-time-label",boardState.address,relayChannel.pin].join("-")}>Time</InputLabel>
                                        <Select 
                                            id={["relay-chan-time",boardState.address,relayChannel.pin].join("-")}
                                            labelId={["relay-chan-time-label",boardState.address,relayChannel.pin].join("-")}
                                            name={timeName}
                                            value={that.state.relayFormState[timeName] || relayChannel.defaultTime || 2}
                                            onChange={that.handleRelayFormChange}
                                            >
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={15}>15</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={30}>30</MenuItem>
                                            <MenuItem value={40}>40</MenuItem>
                                            <MenuItem value={60}>60</MenuItem>
                                            <MenuItem value={90}>90</MenuItem>
                                            <MenuItem value={120}>120</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Button variant="contained" color="secondary" disabled={that.state.busy || boardState.busy} onClick={that.clickCloseRelay.bind(that, boardState.address, relayChannel.pin)}>Close</Button>
                                </Paper>
                            </Grid>
                        }) }
                    </Grid>
                </Container>
            }) }
        </Container>
    }
}

export default withStyles(useStyles)(RelayBoards)
