import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Amplify from 'aws-amplify';
import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import awsconfig from './aws-exports';
import Embed from './Embed';
import { makeStyles } from '@material-ui/core/styles';

import Environment from './routes/Environment'

import {
    Link,
    HashRouter as Router,
    Switch,
    Route
} from "react-router-dom";

Amplify.configure(awsconfig);

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(2)
  },
}));

function App() {
  return (
    <Router>
        <Switch>
            <Route path = "/Environment/:environmentId" component={Environment} />
            <Route path = "/">
                <ul>
                    <li><Link to="/Environment/157BodminRoad">157 Bodmin Road</Link></li>
                    <li><Link to="/Environment/56AbelYard">56 Abel Yard</Link></li>
                </ul>
            </Route>
        </Switch>
    </Router>
  );
}

export default withAuthenticator(App);
