import React from 'react';
import { API, Auth } from 'aws-amplify';

import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';

const useStyles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "90%"
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    margin: theme.spacing(1)
  }
});

class Law extends React.Component {

    constructor(props){
        super(props)

        this.state = {
            codes : [],
            times : [],
            code : "",
            time : 0,
            busy : false
        }

        this.handleDropdownChange = this.handleDropdownChange.bind(this)
        this.setLaw = this.setLaw.bind(this)
    }

    handleDropdownChange(event) {
        this.state[event.target.name] = event.target.value
        this.setState({ [event.target.name] : event.target.value })
    }

    async setLaw() {
        let envId = (this.props.environment || {}).id

        const data = await Auth.currentSession();
        const jwtToken = data.idToken.jwtToken;
        const payloadSub = data.idToken.payload.sub;
        const email = data.idToken.payload.email;
        
        const params = { 
            headers: {},
            response: true,
            queryStringParameters: {
                jwtToken: jwtToken,
                payloadSub: payloadSub,
                email: email,
                environmentId : envId
            },
            body : { code : this.state.code, time : this.state.time }
        }

        this.setState({ busy : true })

        try {
            const state = await API.post('quicksight', '/Law/' + envId + "/set", params);
        } catch (e) {
            console.warn(e, e.stack)
        }

        this.setState({busy : false})

    }

    componentDidMount() {
        this.getLaw()
    }

    async getLaw() {
        let law = (this.props.environment || {}).law

        if (!law) {
            return
        }

        this.setState({ codes : law.codes, code : law.codes[0], times : law.times })
    }

    render() {
        const {classes} = this.props;
        return this.state.codes.length ? <Paper className={classes.paper}>
            <Typography variant = "h5" component = "h5">Law</Typography>
            <FormControl className={classes.formControl}>
                <InputLabel id={"law-control-label"}>Code</InputLabel>
                <Select 
                    id = "law-control-code"
                    labelId="law-control-code-label"
                    name="code" 
                    value={this.state.code}
                    fullWidth={true}
                    onChange={this.handleDropdownChange}
                    >
                    { this.state.codes.map(function (code) {
                        return <MenuItem key={code} value={code}>{code}</MenuItem>
                    }) }
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id={"time-control-label"}>Time</InputLabel>
                <Select 
                    id = "time-control-code"
                    labelId="time-control-code-label"
                    name="time" 
                    value={this.state.time}
                    fullWidth={true}
                    onChange={this.handleDropdownChange}
                    >
                    { this.state.times.map(function (time) {
                        return <MenuItem key={time.value} value={time.value}>{time.label}</MenuItem>
                    }) }
                </Select>
                <Button variant="contained" color="secondary" onClick={this.setLaw} disabled={this.state.busy}>Set</Button>
            </FormControl>
        </Paper> : <></>
    }
}

export default withStyles(useStyles)(Law)
