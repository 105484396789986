import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import { AmplifySignOut } from '@aws-amplify/ui-react';

import config from '../config';

import Grid from '@material-ui/core/Grid';
import Embed from '../Embed';
import RelayBoards from '../RelayBoards';
import Law from '../Law';

const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(2)
  },
}));

export default class Environment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error : false,
            loading : true,
            environment : {}
        }
    }

    componentDidMount() {
        let environmentId = this.props.match.params.environmentId 
        this.loadEnvironment(environmentId)
    }

    async loadEnvironment(envId) {
        this.setState({ error : false, loading : true });

        try {
            let env = config.environments[envId];

            if (!env) {
                throw new Error("Enironment: " + envId + " not found.");
            }

            this.setState({ environment : env, loading : false })
        } catch (e) {
            console.warn(e, e.stack);
            this.setState({ error : e.message || e });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                { !this.state.loading && <Container maxWidth="md">
                    <AppBar position="static">
                        <Toolbar>
                            <Typography variant="h6"> 
                                { this.state.environment.name }
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <RelayBoards environment={this.state.environment} />
                    <Container>
                        <Container>
                            <Grid container spaceing={3}>
                                <Grid item xs={12} sm={3}>
                                    <Law environment={this.state.environment} />
                                </Grid>
                            </Grid>
                        </Container>
                    </Container>
                    <Embed environment={this.state.environment} />
                </Container> }
                <AmplifySignOut />
            </div> 
        )
    }
}
