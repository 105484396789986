/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:4c5829c1-1e50-4b3e-826c-6abe99251796",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_ouSQU3QNb",
    "aws_user_pools_web_client_id": "t45ki8v549n0lvmghljsnkiqt",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "quicksight",
            "endpoint": "https://hj5tue5jx3.execute-api.eu-west-1.amazonaws.com/live",
            "region": "eu-west-1"
        }
    ],
    "aws_content_delivery_bucket": "spw-plants-amplify-live",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://d13q4naht880tn.cloudfront.net"
};


export default awsmobile;
